<template>
  <div class="author">
    <el-button type="primary" icon="el-icon-plus" @click="addTeam">添加论文作者</el-button>
    <el-table
      class="ly_table"
      :data="authorTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
    >
      <template slot="empty">
        <img src="@/assets/img/table.png" alt="没有数据" srcset />
      </template>
      <el-table-column type="index" label="序号" align="center" width="80"/>
      <el-table-column prop="authorNameEng" label="作者名称(英文)" align="center" />
      <el-table-column prop="authorNameCha" label="作者名称(中文)" align="center" />
      <el-table-column prop="affiliation" label="所属机构(英文)" align="center" />
      <el-table-column prop="affiliationCha" label="所属机构(中文)" align="center" />
      <el-table-column prop="researchInterest" label="研究兴趣" align="center" />
      <el-table-column prop="email" label="邮箱" align="center" />
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <button-table
            @buttonClick="editItem(scope.$index,scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
          />
          <button-table
            @buttonClick="deleteItem(scope.$index,scope.row)"
            content="删除"
            icon="jr-iconjr-icon-delect"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      ref="addVisible"
      :title="dialogType ? '编辑论文作者' : '添加论文作者'"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
      center
    >
      <el-form ref="authorRef" :model="authorObj" :rules="authorRules" label-width="130px">
        <el-form-item label="作者名称(英文)：" prop="authorNameEng">
          <el-input v-model="authorObj.authorNameEng" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="作者名称(中文)：">
          <el-input v-model="authorObj.authorNameCha" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="所属机构(英文)：">
          <el-input v-model="authorObj.affiliation" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="所属机构(中文)：">
          <el-input v-model="authorObj.affiliationCha" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="研究兴趣：">
          <el-input v-model="authorObj.researchInterest" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="authorObj.email" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import ButtonTable from '@/components/ButtonTable.vue'
import { pagination } from '@/mixins/pagination'
import {
  getPaperAuthor,
  addPaperAuthor,
  updatePaperAuthor,
  deletePaperAuthor,
  getPaperAuthorById,
} from '@/api/paper'

export default {
  name: 'Author',

  components: {
    ButtonTable,
  },

  mixins: [pagination],

  props: {
    infoType: {
      type: Number,
    },
    authorTable: {
      type: Array,
    },
    id: {
      type: Number,
    },
    activeName: {
      type: String,
    },
  },

  data() {
    return {
      dialogVisible: false,
      dialogType: 0,
      authorObj: {
        authorNameEng: '', // 作者名称(英文)
        authorNameCha: '', // 作者名称(中文)
        affiliation: '', // 所属机构(英文)
        affiliationCha: '', // 所属机构(中文)
        researchInterest: '', // 研究兴趣
        email: '', // 邮箱
      },
      authorRules: {
        authorNameEng: [
          { required: true, message: '请输入作者名称(英文)', trigger: 'blur' },
        ],
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        paperId: '',
      },
      currentIndex: '',
    }
  },

  watch: {
    activeName: {
      handler(newVal) {
        if (newVal === 'second' && this.infoType) {
          this.search()
        }
      },
    },
  },

  methods: {
    async search() {
      this.queryInfo.paperId = this.id

      const res = await getPaperAuthorById(this.queryInfo)

      if (res.code === 200) {
        this.$emit('update:authorTable', res.data.list)

        this.total = res.data.total
      }
    },
    addTeam() {
      this.dialogType = 0

      this.dialogVisible = true
    },
    editItem(index, row) {
      this.currentIndex = index

      Object.assign(this.authorObj, row)

      this.dialogType = 1

      this.dialogVisible = true
    },
    // 删除
    deleteItem(index, row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.infoType) {
            const res = await deletePaperAuthor([row.id])
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.search()
            }
          } else {
            this.$emit('deleteAuthor', index)
          }
        })
        .catch(() => {})
    },

    submitForm() {
      this.$refs.authorRef.validate(async (valid) => {
        if (valid) {
          let res
          if (!this.infoType && !this.dialogType) {
            // 论文数据新增作者新增

            this.$emit('addAuthor', this.authorObj)
            this.dialogVisible = false
          } else if (!this.infoType && this.dialogType) {
            // 论文数据新增作者编辑

            this.$emit('updateAuthor', this.currentIndex, this.authorObj)
          } else if (this.infoType && !this.dialogType) {
            // 论文数据编辑作者新增
            const params = {
              ...this.authorObj,
              paperId: this.id,
            }
            res = await addPaperAuthor(params)
          } else {
            // 论文数据编辑作者编辑
            res = await updatePaperAuthor(this.authorObj)
          }
          if (res.code === 200) {
            this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)
            this.search()
          }
          this.dialogVisible = false
        }
      })
    },
    handleClose() {
      this.$refs.authorRef.clearValidate()

      this.dialogVisible = false

      this.authorObj = {
        authorNameEng: '', // 作者名称(英文)
        authorNameCha: '', // 作者名称(中文)
        affiliation: '', // 所属机构(英文)
        affiliationCha: '', // 所属机构(中文)
        researchInterest: '', // 研究兴趣
        email: '', // 邮箱
      }
    },
  },
}
</script>

<style>
</style>
