<template>
  <div class="basicInfo" v-if="isShow">
    <el-form :model="basicInfoObj" :rules="basicInfoRules" ref="basicInfoRef" label-width="120px">
      <el-form-item label="论文年份：">
        <data-time
          :parentYear="basicInfoObj.yearsTime"
          :parentMonth="basicInfoObj.monthTime"
          :parentDay="basicInfoObj.dayTime"
          :isShowDay="false"
          @dateChange="dateChange"
        />
      </el-form-item>
      <el-form-item label="论文标题(英文)：">
        <el-input v-model="basicInfoObj.paperTitleEng" class="lager-input" />
      </el-form-item>
      <el-form-item label="论文标题(中文)：">
        <el-input v-model="basicInfoObj.paperTitleCha" class="lager-input" />
      </el-form-item>
      <el-form-item label="论文文件：" prop="paperFile">
        <upload-file
          :saveUrl.sync="basicInfoObj.paperFile"
          :lookUrl.sync="basicInfoObj.paperFile"
          ref="uploadFile"
        />
      </el-form-item>
      <el-form-item label="论文简介(英文)：">
        <el-input
          v-model="basicInfoObj.paperIntroductionEng"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
        />
      </el-form-item>
      <el-form-item label="论文简介(中文)：">
        <el-input
          v-model="basicInfoObj.paperIntroductionCha"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 20 }"
        />
      </el-form-item>
      <el-form-item label="会议/刊期：" class="radio_common">
        <el-radio-group v-model="basicInfoObj.journalMeeting">
          <el-radio
            v-for="(item,index) in periodicalList"
            :key="item.conference+index"
            :label="item.conference"
          >{{item.conference}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="技术类别：">
        <dic-checkbox-group
          :code.sync="basicInfoObj.technologyCategory"
          tag="技术"
          @updateCode="updateCode"
        />
      </el-form-item>
      <el-form-item label="技术标签：" v-if="infoType">
          <el-input v-model="basicInfoObj.technicalLabel" style="width:80%" type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }" />
        </el-form-item>
      <el-form-item label="代码链接：">
        <el-input v-model="basicInfoObj.codeLink" class="lager-input" />
      </el-form-item>
      <el-form-item label="关键词：" v-if="infoType">
        <el-input
          v-model="basicInfoObj.antistop"
          style="width:80%"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import DataTime from '@/components/DataTime.vue'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import DicRadioGroup from '@/components/DicRadioGroup.vue'

import UploadFile from '@/components/UploadFile.vue'
import {
  getInfo,
  getInfoById,
  addInfo,
  deleteInfo,
  getPeriodicalTag,
} from '@/api/paper'

export default {
  name: 'BasicInfo',

  props: {
    basicInfoObj: {
      type: Object,
    },
  },

  computed: {
    infoType() {
      return Number(this.$route.query.infoType)
    },
  },

  components: {
    DataTime,
    DicRadioGroup,
    DicCheckboxGroup,

    UploadFile,
  },

  data() {
    return {
      // basicInfoObj: {
      //   yearsTime: null, // 年
      //   monthTime: null, // 月
      //   dayTime: null, // 日
      //   paperTitleEng: '', // 论文标题(英文)
      //   paperTitleCha: '', // 论文标题(中文)
      //   paperFile: '', // 论文文件
      //   paperIntroductionEng: '', // 论文简介(英文)
      //   paperIntroductionCha: '', // 论文简介(中文)
      //   journalMeeting: '', // 会议/刊期
      //   technologyCategory: [], // 技术类别
      //   technologyChildrenId: [], // 技术类别子标签
      //   codeLink: '', // 代码链接
      //   dataIntegrity: '', // 关键词
      // },
      isShow:false,
      basicInfoRules: {
        paperFile: [
          { required: true, message: '请上传论文文件', trigger: 'change' },
        ],
      },
      periodicalList: [],
    }
  },
  created() {
    this.search()
    if(!this.infoType) {
        this.isShow = true;
      }else{
        setTimeout(() => {
        this.isShow = true
    }, 600)
      }
  },
  // watch: {
  //   'basicInfoObj.id': {
  //     handler(newVal) {
  //       if (this.basicInfoObj && newVal) {
  //         this.isShow = true;
  //       }
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  methods: {
    dateChange(year, month, day) {
      this.basicInfoObj.yearsTime = year

      this.basicInfoObj.monthTime = month

      this.basicInfoObj.dayTime = day
    },
    updateCode(val, tag) {
      switch (tag) {
        case '技术':
          this.basicInfoObj.technologyChildrenId = val
          break

        default:
          break
      }
    },
    async search() {
      const res = await getPeriodicalTag()

      if (res.code === 200) {
        this.periodicalList = res.data
      }
    },
    changeTag() {
      if (this.basicInfoObj.technologyChildrenId) {
        this.basicInfoObj.technologyCategory =
          this.basicInfoObj.technologyCategory.concat(
            this.basicInfoObj.technologyChildrenId
          )
      }
      this.basicInfoObj.technologyCategory = [
        ...new Set(this.basicInfoObj.technologyCategory),
      ]
    },
  },
}
</script>

<style>
</style>
