<template>
  <div class="add">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="论文基本信息" name="first">
        <basic-info ref="paperInfoRef" :basicInfoObj="basicInfoObj" :infoType="infoType" />
      </el-tab-pane>
      <el-tab-pane label="论文作者" name="second">
        <author
          ref="paperAuthorRef"
          :authorTable.sync="table.currentAuthor"
          :infoType="infoType"
          :id="id"
          :activeName="activeName"
          @addAuthor="addAuthor"
          @updateAuthor="updateAuthor"
          @deleteAuthor="deleteAuthor"
        />
      </el-tab-pane>
      <el-tab-pane label="论文机构" name="third">
        <organization
          ref="paperOrganizationRef"
          :organizationTable.sync="table.currentOrganization"
          :infoType="infoType"
          :id="id"
          :activeName="activeName"
          @addOrganization="addOrganization"
          @updateOrganization="updateOrganization"
          @deleteOrganization="deleteOrganization"
        />
      </el-tab-pane>
    </el-tabs>
    <extra-info
      v-if="infoType"
      :creator="basicInfoObj.createName"
      :modifier="basicInfoObj.updateName"
      :createTime="basicInfoObj.createTime"
      :updateTime="basicInfoObj.updateTime"
    />
    <div class="bottom">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import BasicInfo from './cpns/BasicInfo.vue'
import Author from './cpns/Author.vue'
import Organization from './cpns/Organization.vue'
import ExtraInfo from '@/components/ExtraInfo.vue'
import { addInfo, updateInfo, getInfoById } from '@/api/paper'
export default {
  name: 'Add',

  components: {
    BasicInfo,
    Author,
    Organization,
    ExtraInfo,
  },

  data() {
    return {
      activeName: 'first',
      basicInfoObj: {
        yearsTime: null, // 年
        monthTime: null, // 月
        dayTime: null, // 日
        paperTitleEng: '', // 论文标题(英文)
        paperTitleCha: '', // 论文标题(中文)
        paperFile: '', // 论文文件
        paperIntroductionEng: '', // 论文简介(英文)
        paperIntroductionCha: '', // 论文简介(中文)
        journalMeeting: '', // 会议/刊期
        technologyCategory: [], // 技术类别
        technologyChildrenId: [], // 技术类别子标签
        codeLink: '', // 代码链接
        dataIntegrity: '', // 关键词
      },
      table: {
        author: [], // 作者
        currentAuthor: [], // 作者tabs
        organization: [], // 机构
        currentOrganization: [], // 机构tabs
      },
    }
  },
  mounted(){
    if (this.infoType) {
      // document.querySelector('.el-tabs__content').style.height =
      // document.body.clientHeight - 310 + 'px'
    } else {
      document.querySelector('.el-tabs__content').style.height =
        document.body.clientHeight - 240 + 'px'
    }
  },
  computed: {
    infoType() {
      return Number(this.$route.query.infoType)
    },
    id() {
      return Number(this.$route.query.id)
    },
  },
  watch: {
    activeName: {
      handler(newVal) {
        // this.$nextTick(() => {
        // })
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.infoType) {
      this.searchInfoById()
    }
  },
  methods: {
    async searchInfoById() {
      const res = await getInfoById({ id: this.id })

      if (res.code === 200) {
        this.basicInfoObj = res.data

        this.basicInfoObj.technologyCategory = this.formatString(
          this.basicInfoObj.technologyCategory
        )

        this.basicInfoObj.yearsTime = this.formatNumber(
          this.basicInfoObj.yearsTime
        )
        this.basicInfoObj.monthTime = this.formatNumber(
          this.basicInfoObj.monthTime
        )
        this.basicInfoObj.dayTime = this.formatNumber(this.basicInfoObj.dayTime)
      }
    },
    // 将字符串格式化为数字
    formatNumber(val) {
      if (val) {
        return Number(val)
      } else {
        return val
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },
    handleClick(tab, event) {},
    submit() {
      this.$refs.paperInfoRef.$refs.basicInfoRef.validate(async (valid) => {
        if (valid) {
          // this.$refs.paperInfoRef.changeTag()

          if (this.basicInfoObj.technologyChildrenId) {
            this.basicInfoObj.technologyCategory =
              this.basicInfoObj.technologyCategory.concat(
                this.basicInfoObj.technologyChildrenId
              )
          }
          this.basicInfoObj.technologyCategory = [
            ...new Set(this.basicInfoObj.technologyCategory),
          ]
          let res
          if (this.infoType) {
            // Object.assign(this.basicInfoObj, { id: this.id })

            res = await updateInfo(this.basicInfoObj)
          } else {
            const params = {
              paperBasicAddParam: this.basicInfoObj,
              paperAuthorsAddParams: this.table.currentAuthor,
              paperInstitutionsAddParams: this.table.currentOrganization,
            }
            res = await addInfo(params)
          }
          if (res.code === 200) {
            this.$message.success(
              `${this.periodicalType ? '编辑' : '新增'}成功`
            )
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    cancelItem() {
      this.$router.go(-1)
    },
    // 新增机构
    addOrganization(val) {
      this.table.currentOrganization.unshift(val)
    },
    // 编辑机构
    updateOrganization(index, val) {
      this.$set(this.table.currentOrganization, index, val)
    },
    // 删除机构
    deleteOrganization(index) {
      this.table.currentOrganization.splice(index, 1)
    },
    // 新增作者
    addAuthor(val) {
      this.table.currentAuthor.unshift(val)
    },
    // 编辑作者
    updateAuthor(index, val) {
      this.$set(this.table.currentAuthor, index, val)
    },
    // 删除作者
    deleteAuthor(index) {
      this.table.currentAuthor.splice(index, 1)
    },
  },
}
</script>

<style lang="less" scoped>
.add {
  background: #fff;
  padding: 0px 18px 0 18px;
  overflow: auto;
}
/deep/ .el-tabs__content{
  overflow: auto;
}
</style>
