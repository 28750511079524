<template>
  <div class="organization">
    <el-button type="primary" icon="el-icon-plus" @click="addTeam">添加论文机构</el-button>
    <el-table
      class="ly_table"
      :data="organizationTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
    >
      <template slot="empty">
        <img src="@/assets/img/table.png" alt="没有数据" srcset />
      </template>
      <el-table-column type="index" label="序号" align="center" width="80"/>
      <el-table-column prop="institutionsNameEng" label="机构名称(英文)" align="center" />
      <el-table-column prop="institutionsNameCha" label="机构名称(中文)" align="center" />
      <el-table-column prop="institutionsType" label="机构类型" align="center" />
      <el-table-column label="所在地区" align="center">
        <template v-slot="{row}">
          {{row.country?row.country:''}}
          {{row.province?'-'+row.province:''}}
          {{row.city?'-'+row.city:''}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scope">
          <button-table
            @buttonClick="editItem(scope.$index,scope.row)"
            content="编辑"
            icon="jr-iconjr-icon-edit"
          />
          <button-table
            @buttonClick="deleteItem(scope.$index,scope.row)"
            content="删除"
            icon="jr-iconjr-icon-delect"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      ref="addVisible"
      :title="dialogType ? '编辑论文机构' : '添加论文机构'"
      :visible.sync="dialogVisible"
      width="65%"
      @close="handleClose"
      center
    >
      <el-form
        ref="organizationRef"
        :model="organizationObj"
        :rules="organizationRules"
        label-width="130px"
      >
        <el-form-item label="机构名称(英文)：" prop="institutionsNameEng">
          <el-input v-model="organizationObj.institutionsNameEng" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="机构名称(中文)：">
          <el-input v-model="organizationObj.institutionsNameCha" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="机构类型：">
          <dic-radio-group :code.sync="organizationObj.institutionsType" type-code="036" />
        </el-form-item>
        <el-form-item label="所在地区：">
          <cascader-area
            ref="cascaderArea"
            :country="organizationObj.country"
            :province="organizationObj.province"
            :city="organizationObj.city"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import CascaderArea from '@/components/CascaderArea.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import DicRadioGroup from '@/components/DicRadioGroup.vue'
import { pagination } from '@/mixins/pagination'
import {
  getPaperOrganization,
  addPaperOrganization,
  updatePaperOrganization,
  deletePaperOrganization,
  getPaperOrganizationById,
} from '@/api/paper'
export default {
  name: 'Organization',

  props: {
    infoType: {
      type: Number,
    },
    organizationTable: {
      type: Array,
    },
    id: {
      type: Number,
    },
    activeName: {
      type: String,
    },
  },

  components: {
    ButtonTable,
    CascaderArea,
    DicRadioGroup,
  },

  mixins: [pagination],

  data() {
    return {
      dialogVisible: false,
      dialogType: 0,
      // organizationTable: [],
      organizationObj: {
        institutionsNameEng: '', // 机构名称(英文)
        institutionsNameCha: '', // 机构名称(中文)
        institutionsType: '', // 机构类型
        country: '', // 国家
        province: '', // 省份
        city: '', // 城市
      },
      organizationRules: {
        institutionsNameEng: [
          { required: true, message: '请输入机构名称(英文)', trigger: 'blur' },
        ],
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        paperId: '',
      },
      currentIndex: '',
    }
  },

  watch: {
    activeName: {
      handler(newVal) {
        if (newVal === 'third' && this.infoType) {
          this.search()
        }
      },
    },
  },

  methods: {
    async search() {
      this.queryInfo.paperId = this.id

      const res = await getPaperOrganizationById(this.queryInfo)

      if (res.code === 200) {
        this.$emit('update:organizationTable', res.data.list)

        this.total = res.data.total
      }
    },
    addTeam() {
      this.dialogType = 0

      this.dialogVisible = true
    },
    editItem(index, row) {
      this.currentIndex = index

      Object.assign(this.organizationObj, row)

      this.dialogType = 1

      this.dialogVisible = true
    },
    deleteItem(index, row) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          // 编辑
          if (this.infoType) {
            const res = await deletePaperOrganization([row.id])

            if (res.code === 200) {
              this.$message.success('删除成功')

              this.search()
            }
          } else {
            this.$emit('deleteOrganization', index)
          }
        })
        .catch(() => {})
    },
    submitForm() {
      this.$refs.organizationRef.validate(async (valid) => {
        if (valid) {
          let res
          if (!this.infoType && !this.dialogType) {
            // 论文数据新增机构新增
            this.$emit('addOrganization', this.organizationObj)
            this.dialogVisible = false
          } else if (!this.infoType && this.dialogType) {
            // 论文数据新增机构编辑

            this.$emit(
              'updateOrganization',
              this.currentIndex,
              this.organizationObj
            )
          } else if (this.infoType && !this.dialogType) {
            // 论文数据编辑机构新增
            const params = {
              ...this.organizationObj,
              paperId: this.id,
            }

            res = await addPaperOrganization(params)
          } else {
            // 论文数据编辑机构编辑
            res = await updatePaperOrganization(this.organizationObj)
          }
          if (res.code === 200) {
            this.$message.success(`${this.dialogType ? '编辑' : '新增'}成功`)
            this.search()
          }

          this.dialogVisible = false
        }
      })
    },
    handleClose() {
      this.organizationObj = {
        institutionsNameEng: '', // 机构名称(英文)
        institutionsNameCha: '', // 机构名称(中文)
        institutionsType: '', // 机构类型
        country: '', // 国家
        province: '', // 省份
        city: '', // 城市
      }

      this.$refs.organizationRef.clearValidate()

      this.$refs.cascaderArea.clear()

      this.dialogVisible = false
    },
    searchCountry(val) {
      this.organizationObj.country = val
    },
    searchProvince(val) {
      this.organizationObj.province = val
    },
    searchCity(val) {
      this.organizationObj.city = val
    },
  },
}
</script>

<style>
</style>
